@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

// Font Custom Icon

@font-face {
  font-family: "icomoon";
  src: url("../images/fonts/icomoon.eot");
  src: url("../images/fonts/icomoon.eot") format("embedded-opentype"),
    // url('../images/fonts/icomoon.ttf') format('truetype'),
    url("../images/fonts/icomoon.woff") format("woff"),
    url("../images/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fleet-activity:before {
  content: "\e900";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-fuel:before {
  content: "\e902";
}
.icon-cash-flow:before {
  content: "\e903";
}
.icon-station:before {
  content: "\e905";
}
.icon-filter:before {
  content: "\e906";
}
.icon-download:before {
  content: "\e907";
}
.icon-checkmark:before {
  content: "\ea10";
}

.icon-gear:before {
  content: "\e90d";
}

.icon-arrow-up:before {
  content: "\e920";
}

.icon-arrow-down:before {
  content: "\e921";
}

.icon-list:before {
  content: "\e922";
}

.icon-map:before {
  content: "\e923";
}

.icon-chevron-right:before {
  content: "\e924";
}

.icon-chevron-left:before {
  content: "\e925";
}

.icon-access:before {
  content: "\e910";
}

.icon-help:before {
  content: "\e913";
}
