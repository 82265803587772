.modal {
  width: 1100px;
  margin-top: 100px;
}
.headerContainer {
  margin-bottom: 30px;
}
.form {
  width: 400px;
  margin-bottom: 30px;

  > .subform {
    width: 360px;
  }
}

.title {
  margin-bottom: 5px;
}

.checkboxes {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.mobileField {
  padding-left: 30px;
  > p {
    width: 50%;
  }
}

.error {
  margin-top: -22px !important;
  font-size: 12px;
  color: var(--color-deep-red);
}

.estimatedPesoValue {
  margin: 8px 36px 0 0;
  color: var(--color-night);
  .estimatedPesoValueAmount {
    font-weight: 500;
  }
}
.popOver {
  display: inline-block;
}
.linkStationList {
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-purple);
  font-weight: 500;
}
.table {
  margin-top: 20px;
  white-space: nowrap;
}

.backlink {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
}

.backlinkContent {
  display: flex;
  align-items: center;
  display: row;
}

.icon {
  color: var(--color-purple);
}

.backlinkText {
  font-weight: 500;
  color: var(--color-purple);
  text-decoration: underline;
}

.dateContainer {
  width: 400px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.dateRange {
  width: 240px;
  flex-direction: row;
}

.duration {
  width: 150px;
  flex-direction: row;
}

.limit {
  align-items: baseline;
}

.fleetStations {
  margin-top: 5px;
}
