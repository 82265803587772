.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttonContainer {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 22px;
    .secondaryButtonContainer,
    .primaryButtonContainer {
      button {
        border-radius: 5px;
      }
    }
    .secondaryButtonContainer {
      button {
        color: var(--color-prussian-blue);
        background-color: var(--color-light-gold);
      }
    }
  }
}

.title {
  margin: 0;
  margin-bottom: 3px;
  // color: var(--color-prussian-blue);
  font-size: 25px;
}
