.pagination {
  margin-top: 20px;
}

.noSearchResultRender {
  display: flex;
  align-items: center;
  margin-top: 40px;
  > div {
    margin: auto;
    text-align: center;
  }
  .okayGotIt {
    margin-top: 40px;
    font-weight: 500;
  }
}
